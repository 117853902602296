import { Link } from 'react-router-dom';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import {
  ForgotPasswordProvider,
  useForgotPasswordData
} from '@jetshop/core/components/Auth/ForgotPasswordProvider';
import Head from '@jetshop/core/components/Head';
import t from '@jetshop/intl';
import React from 'react';
import { GlobalError } from '../Forms/GlobalError';
import Input from '../Forms/Input';
import ButtonWithLoading from '../ui/Button';
import { smallCaps } from '../ui/Headings';
import { activeSegment, smallSection } from './UI/Form';
import MaxWidth from '../Layout/MaxWidth';
import { cx } from 'linaria';
import { styled } from 'linaria/react';

const TextLink = styled(Link)`
  text-decoration: none;
  color: black;
  text-align: right;
  font-size: 0.875em;
  :hover {
    opacity: 0.8;
  }
  &.signup-link {
    margin-left: auto;
  }
`;

export default function ForgotPasswordPage(props) {
  return (
    <MaxWidth style={{ alignItems: 'center', marginTop: '2em' }}>
      <ForgotPasswordProvider>
        <section
          className={cx(smallSection, activeSegment)}
          style={{ marginTop: '5rem', marginLeft: 'auto', marginRight: 'auto' }}
        >
          <h1 className={smallCaps}>{t(`Forgot password`)}</h1>
          <Head data={{ title: 'Forgot Password' }} />
          <ForgotPasswordForm />
        </section>
      </ForgotPasswordProvider>
    </MaxWidth>
  );
}

function ForgotPasswordForm() {
  const {
    submitted,
    isSubmitting,
    globalError,
    isValid,
    emailInputProps
  } = useForgotPasswordData();

  const { routes } = useShopConfig();

  if (submitted) {
    return (
      <p style={{ fontSize: '12px', marginBottom: '20px' }}>
        Forespørselen om tilbakestilling av passord er sendt. Sjekk e-posten
        din, du vil motta en link for å tilbakestille passordet ditt. <br />
        Mottar du ikke linken betyr det sanynligvis at du ikke har registrert
        din profil. Du har ikke automatisk en profil selv om du har handlet
        tidligere. <br />
        Klikk{' '}
        <TextLink
          to={routes.signup.path}
          style={{
            fontWeight: 'bold',
            textDecoration: 'underline'
          }}
        >
          her
        </TextLink>{' '}
        for å opprette en profil.
      </p>
    );
  }

  return (
    <>
      <Input label={t('What is your email address?')} {...emailInputProps} />

      {globalError && (
        <GlobalError style={{ marginBottom: '1em' }}>{globalError}</GlobalError>
      )}

      <ButtonWithLoading
        loading={isSubmitting}
        type="submit"
        disabled={!isValid}
      >
        {t('Submit')}
      </ButtonWithLoading>
    </>
  );
}
